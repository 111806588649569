/**
 * CIconBackExport
 * @author Tevin
 */

import React from 'react';
import PropTypes from 'prop-types';

export class CIconBackExport extends React.Component {
    static propTypes = {
        // 样式
        className: PropTypes.string,
    };

    static defaultProps = {};

    render() {
        return (
            <span
                className={[
                    'anticon',
                    'c-icon-row-back-export',
                    this.props.className,
                ].join(' ')}
            >
                <svg
                    viewBox="0 0 1024 1024"
                    focusable="false"
                    width="0.9em"
                    height="0.9em"
                    fill="currentColor"
                    aria-hidden="true"
                >
                    <path
                        d="M966.786773 588.19112a42.051734 42.051734 0 0 0-41.983469 42.051734v294.020809H84.587814V84.116439h294.020809a42.051734 42.051734 0 0 0 0-84.035203H84.65608C38.167069 0.012971 0.552612 37.559162 0.552612 83.979907V924.331929c0 46.420745 37.546191 84.035202 83.966936 84.035202h840.283756c46.420745 0 83.966936-37.546191 83.966937-83.966936V630.174588a42.051734 42.051734 0 0 0-41.983468-42.051734z m29.763889-575.822039c-0.273063-0.204797-0.273063-0.546126-0.546126-0.682658a39.730697 39.730697 0 0 0-29.081232-11.605187H630.782495a42.051734 42.051734 0 0 0 0 83.966937h236.404473L348.162075 603.209597a39.798963 39.798963 0 0 0 0 56.387552 39.730697 39.730697 0 0 0 56.387553 0l520.185411-520.321942v238.930306a42.051734 42.051734 0 0 0 84.035202 0V42.064705a42.051734 42.051734 0 0 0-12.287844-29.695624z"
                        p-id="5103"
                    ></path>
                </svg>
            </span>
        );
    }
}
