/**
 * CInlineSearch
 * @author Tevin
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Form, Button, Spin } from 'antd';
import { DoubleRightOutlined, LoadingOutlined } from '@ant-design/icons';
import './cInlineSearch.scss';

export class CInlineSearch extends React.Component {
    static propTypes = {
        // 异步初始化检测标记，轮询检查，直到数值为真时重置表单（过时方法）
        initialized: PropTypes.bool,
        // 样式名
        className: PropTypes.string,
        // 显示排列方式
        display: PropTypes.oneOf(['line', 'arrage']),
        // 更多显示文本
        moreText: PropTypes.string,
        // 加载中状态
        loading: PropTypes.bool,
        // 项
        items: PropTypes.array,
        // 值变化回调
        onValuesChange: PropTypes.func,
        // 完成回调
        onFinish: PropTypes.func,
    };

    static defaultProps = {
        display: 'line',
        moreText: '筛选项',
        loading: false,
        onValuesChange: () => null,
    };

    constructor(props) {
        super(props);
        this.state = {
            folding: true,
        };
        this.$refs = {};
        this._data = {
            initialized: this.props.initialized,
        };
    }

    _checkInitialization() {
        if (!this.$refs.form) {
            return;
        }
        if (this.props.initialized !== this._data.initialized) {
            this._data.initialized = this.props.initialized;
            setTimeout(() => {
                this.$refs.form.resetFields();
            }, 10);
        }
    }

    _createItems() {
        let searchItems = [...this.props.items];
        if (this.props.display === 'arrage') {
            // 折叠模式
            const needFold = searchItems.filter(item => item.fold);
            if (needFold.length > 0) {
                searchItems.push({
                    render: () => (
                        <Button
                            className={[
                                'c-line-search-fold',
                                this.state.folding ? 'close' : 'open',
                            ].join(' ')}
                            type="dashed"
                            icon={<DoubleRightOutlined />}
                            onClick={evt =>
                                this.setState({ folding: !this.state.folding })
                            }
                        >
                            {(this.state.folding ? '更多' : '折叠') + this.props.moreText}
                        </Button>
                    ),
                });
            }
            // 排序
            if (searchItems.filter(item => item.line).length > 0) {
                const tail = [];
                const normal = [];
                searchItems.forEach(item => {
                    if (item.line === 'tail') {
                        tail.push(item);
                    } else {
                        normal.push(item);
                    }
                });
                searchItems = [...normal, ...tail];
            }
        }
        return searchItems;
    }

    _renderItems(items) {
        return items.map((item, index) => {
            const { render, className, inlineGroup, fold, ...resetProp } = item;
            // 折叠
            if (this.state.folding && fold) {
                return null;
            }
            // 渲染组
            if (inlineGroup) {
                if (typeof item.label === 'undefined') {
                    return (
                        <span
                            className={['c-inline-search-group', className].join(' ')}
                            key={index}
                        >
                            {this._renderItems(inlineGroup)}
                        </span>
                    );
                } else {
                    return (
                        <Form.Item
                            {...resetProp}
                            className={['c-inline-search-group', className].join(' ')}
                            key={item.name || index}
                        >
                            {this._renderItems(inlineGroup)}
                        </Form.Item>
                    );
                }
            }
            // 渲染单项
            else {
                return (
                    <Form.Item
                        {...resetProp}
                        className={[
                            typeof item.label === 'undefined'
                                ? 'c-inline-search-nolabel'
                                : '',
                            className,
                        ].join(' ')}
                        key={item.name || index}
                    >
                        {render()}
                    </Form.Item>
                );
            }
        });
    }

    render() {
        this._checkInitialization();
        const items = this._createItems();
        return (
            <div
                className={[
                    'c-inline-search',
                    this.props.display === 'arrage' && !this.state.folding
                        ? 'c-inline-search-arrage'
                        : '',
                    this.props.className,
                ].join(' ')}
            >
                <Spin spinning={this.props.loading} indicator={<LoadingOutlined spin />}>
                    <Form
                        layout="inline"
                        ref={elm => (this.$refs.form = elm)}
                        initialValues={this.props.initialValues}
                        onValuesChange={(changed, values) =>
                            this.props.onValuesChange(changed, values)
                        }
                        onFinish={evt => this.props.onFinish(evt)}
                    >
                        {this._renderItems(items)}
                    </Form>
                </Spin>
            </div>
        );
    }

    $reset() {
        this.$refs.form.resetFields();
    }
}
