/**
 * CExportModal - 导出进度弹窗
 * @author chensi
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Button, Progress, message } from 'antd';
import './cExportModal.scss';
import { CheckOutlined } from '@ant-design/icons';
import { CItemEditor } from '@components/plugins/itemEditor/CItemEditor';

export class CExportModal extends React.Component {
    static propTypes = {
        // 请求方法
        onSaveExportRequest: PropTypes.func,
        // 导出结果方法
        onGetExportResult: PropTypes.func,
        // 弹窗标题
        title: PropTypes.string,
        // 按钮名字
        btnName: PropTypes.string,
        // 按钮的颜色类名,需要在父组件定义好类名的样式  默认btn-info 颜色值 #00b2c2
        btnClassName: PropTypes.string,
        // 占位元素尺寸按钮大小
        size: PropTypes.string,
        // 定义底部按钮名字
        footerBtn: PropTypes.array,
        // 定义导出进行和完成内容
        exportContent: PropTypes.array,
        // 点击关闭按钮之后的回调-用于刷新页面
        onRefreshDataResource: PropTypes.func,
        // 打开前进行条件判断，参数是false为不打开弹窗，默认打开
        onBeforeOpenEditor: PropTypes.func,
    };

    static defaultProps = {
        title: '系统提示',
        btnName: '全部导出',
        footerBtn: ['下载文件', '关闭'],
        exportContent: ['正在进行导出，请稍后...', '导出已完成！'],
        btnClassName: 'btn-info',
        onRefreshDataResource: () => null,
        onBeforeOpenEditor: callback => callback(true),
    };

    constructor(props) {
        super(props);
        this.state = {
            // 导出完成开关
            exportFinishFlag: false,
            // 导出进度
            loadPercent: 0,
        };
        this.$refs = {
            editor: null,
        };
        this._data = {
            updatingInterval: null,
            // 下载的文件地址
            downloadUrl: '',
        };
    }

    componentDidMount() {}

    componentWillUnmount() {
        clearInterval(this._data.updatingInterval);
    }

    _handleSaveExportRequest() {
        this.setState({
            loadPercent: 0,
            exportFinishFlag: false,
        });
        // 调用父组件导出请求
        this.props.onSaveExportRequest(res => {
            if (!res) {
                this._handleCloseExportCancel();
                return;
            }
            // 轮询导出结果
            this._data.updatingInterval = setInterval(() => {
                this.props.onGetExportResult(res, result => {
                    if (!result) {
                        this._handleCloseExportCancel();
                        clearInterval(this._data.updatingInterval);
                        return;
                    }
                    // 等等中
                    if (result.resultCode === 2001) {
                        this.setState({
                            loadPercent: result.progress,
                        });
                    }
                    // 成功
                    else if (result.resultCode === 2000) {
                        let isParseJson = this._isParseAbleJson(result.result);
                        if (!isParseJson) {
                            message.error('操作失败！' + result.result);
                            this.$refs.editor.$close();
                            this.setState({
                                loadPercent: 0,
                            });
                            clearInterval(this._data.updatingInterval);
                            return;
                        }
                        this.setState({
                            loadPercent: 100,
                            exportFinishFlag: true,
                        });
                        this._data.downloadUrl = (
                            JSON.parse(result.result) || {}
                        ).downloadUrl;
                        clearInterval(this._data.updatingInterval);
                    }
                    // 失败
                    else if (result.resultCode === 5000) {
                        message.error('操作失败！' + result.result);
                        this.$refs.editor.$close();
                        this.setState({
                            loadPercent: 0,
                        });
                        clearInterval(this._data.updatingInterval);
                    }
                });
            }, 2000);
        });
    }

    _renderContent() {
        return (
            <div>
                <div className="export-progress">
                    <div className="icon">
                        {this.state.exportFinishFlag ? (
                            <CheckOutlined />
                        ) : (
                            <img src="/static/assets/images/loading-200.gif" alt="" />
                        )}
                    </div>
                    <div className="progress">
                        <Progress percent={this.state.loadPercent} />
                    </div>
                    <div className="title">
                        {this.state.exportFinishFlag
                            ? this.props.exportContent[1]
                            : this.props.exportContent[0]}
                    </div>
                </div>
            </div>
        );
    }

    _renderFooterBtn() {
        return (
            <div className="modal-footer-close">
                <Button
                    onClick={() => this._handleDownloadExportResult()}
                    disabled={!this.state.exportFinishFlag}
                    className="modal-footer-btn"
                    type="primary"
                >
                    {this.props.footerBtn[0]}
                </Button>
                <Button
                    onClick={() => {
                        this._handleCloseExportCancel();
                    }}
                    disabled={!this.state.exportFinishFlag}
                    className="modal-footer-btn"
                >
                    {this.props.footerBtn[1]}
                </Button>
                ,
            </div>
        );
    }

    // 关闭按钮
    _handleCloseExportCancel() {
        this.$refs.editor.$close();
        this.props.onRefreshDataResource();
        setTimeout(() => {
            this.setState({
                loadPercent: 0,
                exportFinishFlag: false,
            });
        }, 200);
    }

    // 下载文件按钮
    _handleDownloadExportResult() {
        window.open(this._data.downloadUrl);
    }

    // 无按钮调用打开导出方法
    $open() {
        this.$refs.editor.$open();
    }

    // 是否可以转成json
    _isParseAbleJson(str) {
        try {
            JSON.parse(str);
            return true;
        } catch (error) {
            return false;
        }
    }

    render() {
        return (
            <>
                <CItemEditor
                    title={this.props.title}
                    content={
                        <>
                            {this._renderContent()}
                            {this._renderFooterBtn()}
                        </>
                    }
                    placeholderType="primary"
                    size={this.props.size}
                    className={this.props.btnClassName}
                    closable={false}
                    ref={elm => (this.$refs.editor = elm)}
                    onOpenChange={evt => evt && this._handleSaveExportRequest()}
                    onBeforeOpen={evt => this.props.onBeforeOpenEditor(evt)}
                >
                    {this.props.btnName}
                </CItemEditor>
            </>
        );
    }
}
