/**
 * CTableSummary
 * @author Tevin
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'antd';
import { Tools } from '@components/common/Tools';

export class CTableSummary extends React.Component {
    static propTypes = {
        // 列表原配置
        defaultColumns: PropTypes.array,
        // 列表配置
        columns: PropTypes.array,
        // 表格数据
        datas: PropTypes.array,
        // 是否开启了选择行功能
        hasSelection: PropTypes.bool,
    };

    static defaultProps = {};

    constructor(props) {
        super(props);
        this._data = {
            needed: false,
        };
        this.state = {};
    }

    _checkNeed() {
        for (let col of this.props.columns) {
            if (!col.children || col.children.length === 0) {
                if (col.summary) {
                    return true;
                }
            } else {
                for (let child of col.children) {
                    if (child.summary) {
                        return true;
                    }
                }
            }
        }
        return false;
    }

    _flatEachColumn(type) {
        const flatColumns = [];
        this.props[type].forEach((column, index) => {
            // 普通列（没有子级）
            if (!column.children || column.children.length === 0) {
                if (type === 'defaultColumns') {
                    flatColumns.push({
                        ...column,
                        key: 'col-' + index,
                    });
                } else if (type === 'columns') {
                    flatColumns.push(column);
                }
            }
            // 存在子级的列
            else {
                column.children.forEach((child, index2) => {
                    if (type === 'defaultColumns') {
                        flatColumns.push({
                            ...child,
                            key: 'col-' + index + '-child-' + index2,
                        });
                    } else if (type === 'columns') {
                        flatColumns.push(child);
                    }
                });
            }
        });
        return flatColumns;
    }

    _createSummaryTotals() {
        const summaries = {}; // 合计数据
        const totals = {}; // 合计显示
        // 有数据后，开始创建
        if (this.props.datas.length > 0) {
            const funcColumns = [];
            // 计算所有求和数据
            this._flatEachColumn('defaultColumns').forEach((col, index) => {
                if (col.summary) {
                    if (!col.dataIndex) {
                        return;
                    }
                    if (typeof summaries[col.dataIndex] === 'undefined') {
                        summaries[col.dataIndex] = 0;
                    }
                    // 数值
                    if (col.summary === 'number') {
                        this.props.datas.forEach(record => {
                            summaries[col.dataIndex] += Number(
                                record[col.dataIndex] || 0,
                            );
                        });
                        totals[col.dataIndex] = summaries[col.dataIndex];
                    }
                    // 金额
                    else if (col.summary === 'money') {
                        this.props.datas.forEach(record => {
                            summaries[col.dataIndex] += Number(
                                record[col.dataIndex] || 0,
                            );
                        });
                        totals[col.dataIndex] = Tools.moneyFormat(
                            summaries[col.dataIndex],
                        );
                    }
                    // 吨数保留三位小数
                    else if (col.summary === 'ton') {
                        this.props.datas.forEach(record => {
                            summaries[col.dataIndex] += Number(
                                record[col.dataIndex] || 0,
                            );
                        });
                        totals[col.dataIndex] = Tools.tonFormat(summaries[col.dataIndex]);
                    }
                    // 函数
                    else if (Tools.isFunction(col.summary)) {
                        funcColumns.push(col);
                    }
                }
            });
            // 函数，二次循环
            funcColumns.forEach(col => {
                totals[col.dataIndex] = col.summary(summaries, this.props.datas);
            });
        }
        return totals;
    }

    _renderSummaryCells() {
        const cells = [];
        const totals = this._createSummaryTotals();
        if (this.props.hasSelection) {
            cells.push(<Table.Summary.Cell key="sum--1"></Table.Summary.Cell>);
        }
        const columns = this._flatEachColumn('columns');
        const firstDataIndex = columns.findIndex(col => col.dataIndex);
        columns.forEach((col, index) => {
            if (col.summary) {
                cells.push(
                    <Table.Summary.Cell key={'sum-' + index}>
                        {this.props.datas.length > 0 ? totals[col.dataIndex] : '-'}
                    </Table.Summary.Cell>,
                );
            } else if (index === firstDataIndex) {
                cells.push(
                    <Table.Summary.Cell key={'sum-' + index}>小计</Table.Summary.Cell>,
                );
            } else {
                cells.push(
                    <Table.Summary.Cell key={'sum-' + index}></Table.Summary.Cell>,
                );
            }
        });
        return cells;
    }

    render() {
        if (this._checkNeed()) {
            return (
                <Table.Summary.Row className="c-table-summary">
                    {this._renderSummaryCells()}
                </Table.Summary.Row>
            );
        } else {
            return null;
        }
    }
}
